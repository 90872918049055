import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NavigationComponent } from '../../shared/components';
import { INavigationItem } from '../../shared/interfaces';
import { PermissionEnum, UserService } from '../../core/user';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'dt-basic-layout',
  templateUrl: './basic.layout.html',
  styleUrls: ['./basic.layout.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [RouterModule, NavigationComponent],
})
export class BasicLayoutContainer implements OnInit, OnDestroy {
  public navigationItems: INavigationItem[] = [];

  private _onDestroy: Subject<void> = new Subject<void>();

  constructor(private _userService: UserService, private _breakpointObserver: BreakpointObserver) {}

  public ngOnInit(): void {
    if (this._userService.isUser()) {
      if (this._breakpointObserver.isMatched('(max-width: 1025px)')) {
        this._mobileUserNavigation();
      } else {
        this._userNavigation();
      }
    } else {
      this._clientNavigation();
    }

    if (this._userService.isUser()) {
      this._breakpointObserver
        .observe('(max-width: 1025px)')
        .pipe(takeUntil(this._onDestroy))
        .subscribe((breakpointState: BreakpointState) => {
          if (breakpointState.matches) {
            this._mobileUserNavigation();
          } else {
            this._userNavigation();
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public logout(): void {
    this._userService.logout();
  }

  private _mobileUserNavigation(): void {
    this.navigationItems = [
      {
        type: 'basic',
        label: 'COMMON.ORDERS',
        link: '/orders/list',
      },
      {
        type: 'basic',
        label: 'COMMON.LOGOUT',
        link: '/',
        fn: (): void => this.logout(),
      },
    ];
  }

  private _userNavigation(): void {
    this.navigationItems = [
      {
        type: 'menu',
        label: 'COMMON.ORDERS',
        children: [
          {
            type: 'basic',
            label: 'ORDERS.LIST_ORDER',
            link: '/orders/list',
          },
          {
            type: 'basic',
            label: 'ORDERS.ADD_ORDER',
            link: '/orders/create',
            isHidden: !this._userService.hasAccess([PermissionEnum.ORDERS_CREATE]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.PERIODIC_ORDERS_LIST]),
          },
          {
            type: 'basic',
            label: 'ORDERS.PERIODIC_LIST',
            link: '/periodic-orders/list',
            isHidden: !this._userService.hasAccess([PermissionEnum.PERIODIC_ORDERS_LIST]),
          },
          {
            type: 'basic',
            label: 'ORDERS.ADD_ORDER_PERIODIC',
            link: '/periodic-orders/create',
            isHidden: !this._userService.hasAccess([
              PermissionEnum.PERIODIC_ORDERS_LIST,
              PermissionEnum.PERIODIC_ORDERS_CREATE,
            ]),
          },
          {
            type: 'spacer',
          },
          {
            type: 'basic',
            label: 'COMMON.ARCHIVE',
            link: '/orders/archive',
          },
        ],
      },
      {
        type: 'menu',
        label: 'COMMON.DEVICES',
        isHidden: !this._userService.hasAccess(
          [PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_GROUPS_LIST],
          'ONE_OF',
        ),
        children: [
          {
            type: 'basic',
            label: 'DEVICES.DEVICES_LIST',
            link: '/devices/list',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES.ADD_DEVICE',
            link: '/devices/create',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_CREATE]),
          },
          {
            type: 'spacer',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_LIST, PermissionEnum.DEVICES_GROUPS_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES_GROUPS.DEVICES_GROUPS_LIST',
            link: '/devices-groups',
            isHidden: !this._userService.hasAccess([PermissionEnum.DEVICES_GROUPS_LIST]),
          },
          {
            type: 'basic',
            label: 'DEVICES_GROUPS.ADD_DEVICE_GROUP',
            link: '/devices-groups/create',
            isHidden: !this._userService.hasAccess([
              PermissionEnum.DEVICES_GROUPS_LIST,
              PermissionEnum.DEVICES_GROUPS_CREATE,
            ]),
          },
        ],
      },
      {
        type: 'basic',
        label: 'COMMON.WORKLOG',
        link: '/activities',
      },
      {
        type: 'basic',
        label: 'COMMON.WAREHOUSE',
        link: '/warehouse',
        isHidden: !this._userService.hasAccess([PermissionEnum.WAREHOUSE_ITEMS_LIST]),
      },
      {
        type: 'menu',
        label: 'COMMON.VACATIONS',
        children: [
          {
            type: 'basic',
            label: 'COMMON.CALENDAR',
            link: '/calendar',
            isHidden: !this._userService.hasAccess([PermissionEnum.CALENDAR_ACCESS]),
          },
          {
            type: 'basic',
            label: 'COMMON.VACATIONS_REQUESTS',
            link: '/calendar/vacations',
          },
          {
            type: 'basic',
            label: 'COMMON.SETTINGS',
            link: '/calendar/settings',
            isHidden: !this._userService.hasAccess([PermissionEnum.DAYS_OFF_ACCESS]),
          },
        ],
      },
      {
        type: 'basic',
        label: 'COMMON.CLIENTS',
        link: '/clients',
        isHidden: !this._userService.hasAccess([PermissionEnum.CLIENTS_LIST]),
      },
      {
        type: 'basic',
        label: 'COMMON.USERS',
        link: '/users',
        isHidden: !this._userService.hasAccess([PermissionEnum.USERS_LIST]),
      },
      {
        type: 'menu',
        label: 'COMMON.ACCOUNT',
        children: [
          {
            type: 'basic',
            label: 'COMMON.CHANGE_PASSWORD',
            link: '/auth/change-password',
          },
          {
            type: 'basic',
            label: 'COMMON.LOGOUT',
            link: '/',
            fn: (): void => this.logout(),
          },
        ],
      },
    ];
  }

  private _clientNavigation(): void {
    this.navigationItems = [
      {
        type: 'basic',
        label: 'COMMON.ORDERS',
        link: '/client-panel',
      },
      {
        type: 'basic',
        label: 'COMMON.DEVICES',
        link: '/client-panel/devices',
      },
      {
        type: 'basic',
        label: 'COMMON.CONTACT',
        link: '/client-panel/contact',
      },
      {
        type: 'menu',
        label: 'COMMON.ACCOUNT',
        children: [
          {
            type: 'basic',
            label: 'COMMON.CHANGE_PASSWORD',
            link: '/client-panel/change-password',
          },
          {
            type: 'basic',
            label: 'COMMON.LOGOUT',
            link: '/',
            fn: (): void => this.logout(),
          },
        ],
      },
    ];
  }
}
